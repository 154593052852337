<template>
  <div>
    <div class="d-flex flex-row justify-space-between align-center">
      <div class="d-flex flex-row justify-start align-center">
        <div class="px-5">
          <v-img
            src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
            contain
            :height="96"
            :width="160"
          />
        </div>
        <span class="px-5 text-h5 font-weight-bold"> Procore Rewards </span>
      </div>
      <v-btn
        @click="openPlatform"
        text
        class="text-h5 light-blue--text text--lighten-2 font-weight-bol mr-5"
      >
        <span class="text-none pt-1 px-2">Open the Whistle Platform</span>
        <v-icon right x-large color="light-blue lighten-2">
          mdi-arrow-right-bold-circle-outline
        </v-icon>
      </v-btn>
    </div>
    <v-row justify="space-between" class="px-2">
      <v-col v-if="true" lg="3" cols="12">
        <v-card
          outlined
          elevation="1"
          flat
          class="ml-4 full-height elevation-1 pa-2"
          style="min-height: 70vh"
        >
          <marqeta-widget-manager-v-2
            class="pa-1"
            ref="marqeta-widget"
            :selected-budget="Budget"
          />
        </v-card>
      </v-col>
      <v-col :lg="IsAdmin ? 6 : 9" cols="12">
        <v-card outlined flat class="elevation-1" style="min-height: 70vh">
          <v-container class="white-table-headers">
            <v-data-table
              :headers="headers"
              :items="Programs"
              :loading="display.loading.programs"
              :items-per-page="10"
            >
              <template v-slot:item.project="{ item }">
                <div class="d-flex flex-column">
                  {{ item.name }}
                  <span
                    class="grey--text text--darken-2"
                    v-if="item.status !== 'Active'"
                    >{{ item.status }}</span
                  >
                </div>
              </template>
              <template v-slot:item.metric="{ item }">
                <div
                  class="d-flex flex-column"
                  v-if="item.status === 'Active' || item.status === 'Scheduled'"
                >
                  <span
                    v-if="item.ProgramElements && item.ProgramElements.length"
                    >{{
                      item.ProgramElements[0].MetricDefinition.definition1
                    }}</span
                  >
                  <span
                    class="grey--text text--darken-2"
                    v-if="
                      item.ProgramElements[0].MetricDefinition.definition1 !==
                        'Submittals' && item.ruleAggregation !== 'ONE_TIME'
                    "
                    >Goal: {{ item.ProgramElements[0].value }}
                    {{ item | payoutFrequency }}</span
                  ><span v-else class="grey--text text--darken-2">
                    Goal: {{ item.ProgramElements[0].value }}% completion
                  </span>
                </div>
                <span class="body-1 font-italic" v-else>
                  This program is being configured by a Whistle CSM.
                </span>
              </template>
              <template v-slot:item.reward="{ item }">
                <div
                  class="d-flex flex-column"
                  v-if="
                    item.payoutAmount &&
                      (item.status === 'Active' || item.status === 'Scheduled')
                  "
                >
                  <span class="success--text">{{
                    {
                      amount: item.payoutAmount,
                      type:
                        (item.Budget &&
                          item.Budget.Award &&
                          item.Budget.Award.awardName) ||
                        "Cash"
                    } | award
                  }}</span>
                  <span
                    class="grey--text text--darken-2"
                    v-if="
                      item.ProgramElements[0].MetricDefinition.definition1 !==
                        'Submittals' && item.ruleAggregation !== 'ONE_TIME'
                    "
                    >({{ item | payoutFrequency }})</span
                  >
                </div>
              </template>
            </v-data-table>
            <v-navigation-drawer
              v-model="display.configuration"
              temporary
              fixed
              right
              width="500"
              disable-route-watcher
            >
              <template v-if="!display.fundingBudget">
                <v-toolbar
                  dark
                  color="deep-orange lighten-1"
                  rounded="0"
                  elevation="0"
                >
                  <v-toolbar-title>Add a {{ ResourceTitle }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn depressed dark text @click="stopConfiguring">
                      <v-icon class="">mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="d-flex justify-center align-center my-5">
                  <pill-nav
                    :steps="steps"
                    width="20rem"
                    :height="30"
                    :step="display.configStep"
                    @input="nextStep"
                    color="brandCyan"
                  >
                  </pill-nav>
                  <v-btn
                    rounded
                    color="brandCyan"
                    depressed
                    :dark="!!NextStepReady"
                    :disabled="!NextStepReady"
                    :height="30"
                    @click="
                      v =>
                        display.configStep !== 5
                          ? nextStep(v)
                          : stopConfiguring()
                    "
                  >
                    <template v-if="display.configStep !== 5">
                      <v-icon> mdi-arrow-right-circle </v-icon>
                      Next
                    </template>
                    <template v-else>
                      <v-icon>mdi-check-circle</v-icon>
                      Done
                    </template>
                  </v-btn>
                </div>
                <v-stepper
                  v-if="IsAdmin"
                  class="flat"
                  v-model="display.configStep"
                >
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <div v-if="!StepOneLoading">
                        <v-form ref="form" lazy-validation>
                          <div class="d-flex flex-column">
                            <template v-if="MultiCompany">
                              <div class="d-flex justify-start pb-3">
                                <label class="text-h6">
                                  Choose a company:
                                </label>
                              </div>
                              <div class="d-flex justify-space-between">
                                <v-select
                                  :loading="display.loading.companies"
                                  :disabled="display.loading.people"
                                  outlined
                                  dense
                                  v-model="input.company"
                                  :rules="validation.company"
                                  placeholder="Select a company"
                                  item-text="name"
                                  item-value="id"
                                  color="brandCyan"
                                  :items="Companies"
                                  :menu-props="{ offsetY: true }"
                                  :no-data-text="
                                    display.loading.projects
                                      ? 'Loading companies...'
                                      : 'No companies available'
                                  "
                                >
                                </v-select>
                              </div>
                            </template>
                            <template>
                              <div class="d-flex justify-start">
                                <label class="text-h6">
                                  Select your project
                                </label>
                              </div>
                              <div
                                class="d-flex flex-column justify-space-between"
                              >
                                <div class="d-flex align-self-end align-center">
                                  <label for="showInactive" class="px-3">
                                    Show inactive projects:
                                  </label>
                                  <v-checkbox
                                    size="small"
                                    id="showInactive"
                                    color="brandCyan"
                                    v-model="display.showInactiveProjects"
                                  ></v-checkbox>
                                </div>
                                <v-autocomplete
                                  :loading="
                                    display.loading.projects ||
                                      display.loading.companies
                                  "
                                  :disabled="
                                    display.loading.projects ||
                                      display.loading.companies ||
                                      display.loading.people
                                  "
                                  outlined
                                  dense
                                  v-model="input.project"
                                  :rules="validation.project"
                                  placeholder="Select a project"
                                  item-text="desc"
                                  item-value="id"
                                  color="brandCyan"
                                  :items="Projects"
                                  :menu-props="{ offsetY: true }"
                                  :no-data-text="
                                    display.loading.projects
                                      ? 'Loading projects...'
                                      : 'No projects available'
                                  "
                                >
                                </v-autocomplete>
                              </div>
                            </template>
                            <!-- <div v-if="input.project">
                              <div class="d-flex justify-start pb-3">
                                <label class="text-h6">
                                  Who should receive weekly updates?
                                </label>
                              </div>
                              <div>
                                <v-autocomplete
                                  multiple
                                  chips
                                  deletable-chips
                                  :loading="display.loading.people"
                                  class="text-start text-left mb-4"
                                  :menu-props="{ offsetY: true }"
                                  :items="PeopleOptions"
                                  v-model="input.managers"
                                  placeholder="Start typing a name or email"
                                  :search-input.sync="misc.managerSearchInput"
                                  @change="misc.managerSearchInput = ''"
                                >
                                  <template v-slot:item="data">
                                    <div
                                      class="font-weight-bold text-left text-truncate"
                                    >
                                      {{ data.item.text }}
                                    </div>
                                  </template>
                                </v-autocomplete>
                              </div>
                            </div> -->
                            <div v-if="input.project">
                              <div class="d-flex justify-start pb-3">
                                <label class="text-h6">
                                  Select your participant(s)
                                </label>
                              </div>
                              <v-card flat outlined class="mb-5">
                                <v-card-text>
                                  <div class="rewardees">
                                    <v-chip
                                      class="mx-2 my-2 audience-chip"
                                      v-for="(person, idx) in input.people"
                                      :key="person.id"
                                      close
                                      outlined
                                      color="brandCyan"
                                      @click:close="input.people.splice(idx, 1)"
                                    >
                                      <span class="black--text">{{
                                        person.name
                                      }}</span>
                                    </v-chip>
                                  </div>

                                  <div v-if="display.loading.people">
                                    <v-progress-circular
                                      color="primary"
                                      indeterminate
                                    ></v-progress-circular>
                                  </div>
                                  <div v-else>
                                    <div v-if="People.length">
                                      <v-text-field
                                        v-model="input.filter"
                                        placeholder="Start typing a name or email"
                                        color="brandCyan"
                                      >
                                      </v-text-field>
                                      <div
                                        v-for="person in MatchingPeople"
                                        :key="person.id"
                                      >
                                        <!-- <v-divider class="w-100" inset /> -->
                                        <v-row
                                          align="center"
                                          class="person-selection cursor-pointer"
                                          @click="userRowClicked(person)"
                                        >
                                          <v-col
                                            cols="6"
                                            class="text-left body-2 d-flex align-center justify-start pl-5"
                                          >
                                            <div
                                              class="d-flex flex-column align-center"
                                            >
                                              <v-checkbox
                                                inset
                                                v-model="input.people"
                                                :value="person"
                                                @click.native="
                                                  $event.stopPropagation()
                                                "
                                                color="brandCyan"
                                              >
                                              </v-checkbox>
                                            </div>
                                            <span class="user-select-none">
                                              {{ person.name }}</span
                                            >
                                          </v-col>
                                          <v-col
                                            class="body-2 text-overflow-clip user-select-none"
                                            cols="6"
                                          >
                                            <v-row>
                                              {{ person | vendor }}</v-row
                                            >
                                            <v-row>
                                              {{ person | jobTitle }}</v-row
                                            >
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </div>
                                    <div v-else class="d-flex flex-column">
                                      <span
                                        class="font-italic text--grey text--darken-1 pb-2"
                                      >
                                        No people found for project
                                      </span>
                                      <div>
                                        <!-- <v-btn depressed @click="prevStep" color="primary"
                                >Go Back</v-btn
                              > -->
                                      </div>
                                    </div>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </div>
                          </div>
                        </v-form>
                      </div>
                      <div v-else>
                        <div class="d-flex flex-column align-center">
                          <span class="text-h5 font-weight-bold px-7 my-5"
                            >We're autoconfiguring your program for optimal
                            results</span
                          >
                          <v-progress-circular
                            indeterminate
                            size="64"
                            width="8"
                            class="my-5"
                            color="deep-orange lighten-1"
                          />
                          <span class="body-2 grey--text my-5"
                            >This will only take a moment</span
                          >
                        </div>
                      </div>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-form ref="step2form">
                        <div class="d-flex justify-start align-center pb-4">
                          <label class="text-h6">
                            Choose your requirements
                          </label>
                          <v-tooltip top v-if="!IsSubmittal"
                            ><template v-slot:activator="{ on, attrs }"
                              ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                                >mdi-help-circle-outline</v-icon
                              ></template
                            ><span v-if="input.resource !== 'Daily Logs'"
                              >In order for participants to get paid each
                              {{ CadenceUnit }}, {{ input.goal }}
                              {{ input.resource }} must be submitted inside the
                              daily log.</span
                            ><span v-else
                              >In order for participants to get paid each day,
                              all requirements below must be met along with
                              checking the box to "complete" the daily
                              log.</span
                            ></v-tooltip
                          >
                        </div>
                        <!-- <div class="d-flex justify-start pb-2">
                          <label class="text--disabled body-2">
                            Choose an action to reward
                          </label>
                        </div>
                        <div class="d-flex justify-space-between">
                          <v-select
                            :loading="display.loading.projects"
                            outlined
                            dense
                            v-model="input.resource"
                            color="brandCyan"
                            placeholder="Select a resource"
                            :items="fixtures.resources"
                            :menu-props="{ offsetY: true }"
                          >
                          </v-select>
                        </div> -->
                        <template v-if="!IsSubmittal">
                          <div class="d-flex justify-start pb-0"></div>
                          <div
                            class="d-flex justify-start align-end"
                            v-if="input.resource !== 'Daily Logs'"
                          >
                            <v-text-field
                              class="pr-1"
                              type="number"
                              outlined
                              dense
                              color="brandCyan"
                              style="max-width: 25%"
                              label="Goal"
                              v-model.number="input.goal"
                              placeholder=""
                              :rules="validation.goal"
                              :menu-props="{ offsetY: true }"
                            >
                            </v-text-field>
                            <div class="d-flex align-end pb-5 mb-4 mx-3">
                              logs per
                            </div>
                            <v-select
                              class="pl-1"
                              style="max-width: 35%"
                              outlined
                              dense
                              color="brandCyan"
                              v-model="input.cadence"
                              placeholder="Frequency"
                              :rules="validation.cadence"
                              :items="fixtures.cadences"
                              :menu-props="{ offsetY: true }"
                            >
                            </v-select>
                          </div>

                          <div v-else>
                            <div
                              v-if="DailyLogCadenceEditable"
                              class="d-flex align-center"
                            >
                              On a
                              <v-select
                                class="pl-1"
                                style="max-width: 25%"
                                v-model="input.cadence"
                                placeholder="Frequency"
                                :rules="validation.cadence"
                                :items="fixtures._cadences"
                                :menu-props="{ offsetY: true }"
                              >
                              </v-select>
                              basis
                            </div>
                            <div class="d-flex flex-column mt-2 mb-4">
                              <div
                                class="d-flex align-start"
                                :key="index"
                                v-for="(resource, index) in input.resources"
                              >
                                <v-autocomplete
                                  style="max-width: 60%"
                                  outlined
                                  dense
                                  class="px-1"
                                  v-model="resource.resource"
                                  placeholder="Select a resource"
                                  item-text="text"
                                  item-value="value"
                                  :items="fixtures.dailyLogResources"
                                ></v-autocomplete>
                                <v-text-field
                                  outlined
                                  dense
                                  style="max-width: 25%"
                                  min="0"
                                  max="9999"
                                  type="number"
                                  class="px-1 right-input"
                                  v-model.number="resource.goal"
                                  :rules="validation.goal"
                                ></v-text-field>
                                <v-btn
                                  rounded
                                  dark
                                  fab
                                  x-small
                                  class="mt-1 mx-2 elevation-0"
                                  color="secondary lighten-4"
                                  @click="input.resources.splice(index, 1)"
                                >
                                  <v-icon small>mdi-trash-can</v-icon>
                                </v-btn>
                              </div>
                              <v-btn
                                color="primary"
                                depressed
                                @click="
                                  input.resources.push({
                                    resource: '',
                                    goal: 1
                                  })
                                "
                              >
                                Add field
                              </v-btn>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="d-flex align-start flex-column mb-2">
                            <span class="font-weight-bold">
                              Set your goal and incentives
                            </span>
                            <span class="body-1">
                              Choose the fields we should measure for
                              completion:
                            </span>
                          </div>
                          <div
                            class="d-flex align-center justify-start flex-column hide-scrollbar grey lighten-2 rounded-lg"
                            style="
                              width: 100%;
                              max-height: 15rem;
                              overflow-y: auto;
                            "
                            ref="submittalFields"
                          >
                            <div
                              class="d-flex justify-space-between"
                              style="position: sticky; top: 0px; width: 100%"
                            >
                              <br />
                              <span
                                >Selected: {{ SubmittalFields.length }}</span
                              >
                              <div class="d-flex justify-end grey">
                                <v-btn
                                  small
                                  icon
                                  class="align-self-end"
                                  @click="$refs.submittalFields.scrollTop = 0"
                                >
                                  <v-icon> mdi-arrow-up </v-icon>
                                </v-btn>
                                <v-btn
                                  small
                                  icon
                                  class="align-self-end"
                                  @click="
                                    $refs.submittalFields.scrollTop =
                                      $refs.submittalFields.scrollHeight
                                  "
                                >
                                  <v-icon> mdi-arrow-down </v-icon>
                                </v-btn>
                              </div>
                            </div>
                            <v-row
                              align="start"
                              style="width: 100%"
                              v-for="(field, index) in input.fields"
                              :key="`${field}-${index}`"
                            >
                              <v-col cols="10" style="width: 100%">
                                <v-autocomplete
                                  class="pt-1 mt-0"
                                  style="width: 100%"
                                  dense
                                  placeholder="Add New"
                                  v-model="input.fields[index]"
                                  :items="
                                    field
                                      ? ConfigurableFieldOptions
                                      : AvailableFields
                                  "
                                />
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  v-if="input.fields[index]"
                                  icon
                                  @click="input.fields.splice(index, 1)"
                                >
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                          <div
                            class="d-flex justify-space-between align-start mb-2 mt-6"
                          >
                            <div class="d-flex flex-column align-start pt-2">
                              <label class="body-1">
                                Percent completion goal:
                              </label>
                              <span
                                class="body-1 caption grey--text text--darken-2"
                              >
                              </span>
                            </div>
                            <v-slider
                              persistent-hint
                              :min="1"
                              class="px-5"
                              step="1"
                              color="brandCyan"
                              :max="SubmittalFields.length"
                              thumb-label
                              @input="updateTotalFields"
                              :value="FieldsRequiredForCompletion"
                              ticks
                              :hint="
                                `${input.goal}% (Total fields: ${FieldsRequiredForCompletion})`
                              "
                              :rules="validation.percent"
                            >
                            </v-slider>
                            <v-text-field
                              style="max-width: 15%"
                              class="mt-0 pt-0"
                              small
                              v-model.number="input.goal"
                              :max="100"
                              suffix="%"
                              :min="0"
                              type="number"
                              color="brandCyan"
                            ></v-text-field>
                          </div>
                        </template>
                      </v-form>
                      <!-- <span class="body-2">{{
                  input.project | project(Project)
                }}</span> -->
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <v-form ref="step2form">
                        <div class="d-flex justify-start pb-4">
                          <label class="text-h6">
                            Choose your rewards
                          </label>
                        </div>
                        <template v-if="!IsSubmittal">
                          <div class="d-flex justify-start pb-0"></div>
                          <div class="d-flex justify-space-between">
                            <div class="mt-2">
                              <span
                                >{{
                                  input.resource !== "Daily Logs"
                                    ? ""
                                    : "Daily "
                                }}Reward</span
                              >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                                    >mdi-help-circle-outline</v-icon
                                  ></template
                                ><span v-if="input.resource !== 'Daily Logs'"
                                  >Amount rewarded each time the participant
                                  meets the program requirements.</span
                                ><span v-else
                                  >Amount rewarded each day the participant
                                  marks the log "complete" with all program
                                  requirements met.</span
                                ></v-tooltip
                              >
                            </div>
                            <v-text-field
                              class="pr-1"
                              style="width: 200px; max-width: 50%;"
                              type="number"
                              color="brandCyan"
                              label="Reward"
                              :prefix="IsCashBudget ? '$' : ''"
                              outlined
                              dense
                              v-model.number="input.reward"
                              placeholder="Reward"
                              :rules="validation.reward"
                              :menu-props="{ offsetY: true }"
                            >
                            </v-text-field>
                          </div>
                          <div class="d-flex justify-space-between">
                            <div class="mt-2">
                              <span>Initial Bonus</span>
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                                    >mdi-help-circle-outline</v-icon
                                  ></template
                                ><span
                                  >Optional one-time reward for the first time
                                  participants meet program requirements.</span
                                ></v-tooltip
                              >
                            </div>
                            <v-text-field
                              class="pr-1"
                              style="width: 200px; max-width: 50%;"
                              type="number"
                              color="brandCyan"
                              :prefix="IsCashBudget ? '$' : ''"
                              label="Initial Bonus"
                              outlined
                              dense
                              v-model.number="input.ongoingInitialPayoutAmount"
                              placeholder="Initial Bonus"
                              :rules="validation.bonus"
                              :menu-props="{ offsetY: true }"
                            >
                            </v-text-field>
                          </div>
                          <div class="d-flex justify-space-between">
                            <div class="mt-2">
                              <span>Streak Bonus</span>
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                                    >mdi-help-circle-outline</v-icon
                                  ></template
                                ><span
                                  >Optional one-time reward for participants
                                  that meet the program requirements for the
                                  full duration of the program.</span
                                ></v-tooltip
                              >
                            </div>
                            <v-text-field
                              class="pr-1"
                              style="width: 200px; max-width: 50%;"
                              type="number"
                              color="brandCyan"
                              label="Streak Bonus"
                              :prefix="IsCashBudget ? '$' : ''"
                              outlined
                              dense
                              v-model.number="input.ongoingStreakPayoutAmount"
                              placeholder="Streak Bonus"
                              :rules="validation.bonus"
                            >
                            </v-text-field>
                          </div>
                        </template>
                        <template v-else>
                          <div class="d-flex justify-space-between">
                            <div class="mt-2">
                              <span>Reward</span>
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                                    >mdi-help-circle-outline</v-icon
                                  ></template
                                ><span
                                  >Amount earned if the submittals meet the
                                  program requirements on the end date.</span
                                ></v-tooltip
                              >
                            </div>
                            <v-text-field
                              outlined
                              dense
                              color="brandCyan"
                              class="pr-1"
                              style="width: 200px; max-width: 50%;"
                              type="number"
                              label="Reward"
                              :prefix="IsCashBudget ? '$' : ''"
                              v-model.number="input.reward"
                              placeholder="Reward"
                              :rules="validation.reward"
                              :menu-props="{ offsetY: true }"
                            >
                            </v-text-field>
                          </div>
                        </template>
                        <div class="d-flex flex-column">
                          <div
                            class="d-flex justify-start pb-0 flex-column pr-1"
                          >
                            <label
                              class="text--disabled body-2 align-self-start mb-2"
                            >
                              Budget source
                            </label>
                          </div>
                          <div class="d-flex justify-space-between">
                            <v-select
                              :loading="display.loading.budget"
                              outlined
                              dense
                              hide-details
                              color="brandCyan"
                              v-model="input.rewardBudget"
                              placeholder="Select a budget"
                              :rules="validation.budget"
                              item-text="text"
                              item-value="value"
                              :items="BudgetOptions"
                              :menu-props="{ offsetY: true }"
                              :no-data-text="
                                display.loading.projects
                                  ? 'Loading budgets...'
                                  : 'No budgets available'
                              "
                            >
                            </v-select>
                            <v-spacer />
                          </div>
                          <div class="d-flex justify-end full-width mt-2">
                            <v-btn
                              v-if="Budget"
                              outlined
                              rounded
                              dark
                              x-small
                              color="primary"
                              @click="display.fundingBudget = true"
                              >Add funds</v-btn
                            >
                          </div>
                          <em
                            class="red--text text-left"
                            v-if="InsufficientFunds"
                          >
                            Warning: Insufficient funds - program may not pay
                            out. Please select a different budget or click "Add
                            funds"
                          </em>
                        </div>
                        <div
                          class="d-flex justify-start pt-2 pb-0 flex-column pr-1"
                        >
                          <label class="text--disabled body-2 align-self-start">
                            Program timeframe
                          </label>
                          <div
                            class="d-flex justify-space-between align-center mt-3"
                          >
                            <v-menu
                              ref="start"
                              v-model="display.start"
                              :close-on-content-click="false"
                              min-width="auto"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <!-- style="max-width: 55%" -->
                                <v-text-field
                                  v-model="DateText"
                                  class="mr-1"
                                  dense
                                  color="brandCyan"
                                  label="Start/End Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  outlined
                                  :rules="validation.dates"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                class=""
                                color="brandCyan"
                                outlined
                                show-adjacent-months
                                :min="Now"
                                v-model="input.dates"
                                :type="DatePickerType"
                                no-title
                                range
                              >
                              </v-date-picker>
                            </v-menu>
                          </div>
                          <div class="d-flex justify-end">
                            <v-autocomplete
                              class="ml-1"
                              color="brandCyan"
                              style="max-width: 45%"
                              v-model="input.timezone"
                              label="Timezone"
                              placeholder="Timezone"
                              :items="fixtures.timezones"
                              outlined
                              dense
                            >
                            </v-autocomplete>
                          </div>
                        </div>
                        <v-divider />
                        <div
                          class="d-flex justify-space-between full-width mt-3"
                        >
                          <span>Max payout per person:</span
                          ><span class="font-weight-bold">
                            {{
                              {
                                amount: ProgramCostPerPerson,
                                type:
                                  Budget &&
                                  Budget.Award &&
                                  Budget.Award.awardName
                              } | award
                            }}</span
                          >
                        </div>
                        <div class="d-flex justify-space-between full-width">
                          <span>Max program payout:</span>
                          <span class="font-weight-bold">
                            {{
                              {
                                amount: ProgramCost,
                                type:
                                  Budget &&
                                  Budget.Award &&
                                  Budget.Award.awardName
                              } | award
                            }}</span
                          >
                        </div>
                        <!-- <div class="d-flex justify-center mt-5">
                          <label class="text-h6 mr-2"
                            >Total program payout:</label
                          >
                          <label class="text-h6">{{
                            {
                              amount: ProgramCost,
                              type:
                                Budget && Budget.Award && Budget.Award.awardName
                            } | award
                          }}</label>
                        </div> -->
                      </v-form>
                    </v-stepper-content>
                    <v-stepper-content step="4">
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-column align-start">
                          <div
                            class="mb-2 d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="text-h6 font-weight-bold text-left">
                              Does this look right?
                            </span>
                          </div>
                          <div class="d-flex justify-start">
                            <label class="pr-4"> Project: </label>
                            <div>
                              <v-card
                                class="rounded-pill elevation-0"
                                @click="display.configStep = 1"
                              >
                                <v-alert
                                  outlined
                                  color="deep-orange lighten-1"
                                  class="rounded-pill text-no-wrap py-0 px-2"
                                >
                                  {{ ProjectName }}
                                </v-alert>
                              </v-card>
                            </div>
                          </div>
                          <div class="d-flex justify-start flex-wrap">
                            <label class="pr-4"
                              >{{ input.people?.length || 0 }} participant{{
                                (input.people?.length || 0) > 1 ? "s" : ""
                              }}:</label
                            >
                            <div
                              v-for="person in input.people"
                              :key="person.id"
                              class="pr-2"
                            >
                              <v-card
                                class="rounded-pill elevation-0"
                                @click="display.configStep = 1"
                              >
                                <v-alert
                                  outlined
                                  color="brandCyan"
                                  class="rounded-pill text-no-wrap py-0 px-2"
                                >
                                  {{ person.name }}
                                </v-alert>
                              </v-card>
                            </div>
                          </div>

                          <!-- <v-spacer class="my-1" /> -->
                          <!-- <div class="d-flex justify-start align-end my-2">
                            <label class="pr-4">Metric:</label>
                            <span v-if="input.resource !== 'Daily Logs'">
                              {{ input.resource }} in Daily Log
                            </span>
                            <span v-else>Daily Logs Completion</span>
                          </div> -->
                          <!-- <v-spacer class="my-3" /> -->
                          <div
                            class="d-flex justify-start align-end my-2"
                            v-if="IsSubmittal"
                          >
                            <label class="pr-4">Goal:</label>
                            <span>
                              <template
                                v-if="
                                  SubmittalFields.length &&
                                    SubmittalFields.length > 1
                                "
                              >
                                {{ input.goal }}% completion ({{
                                  FieldsRequiredForCompletion
                                }}/{{
                                  SubmittalFields.length
                                }}
                                fields)</template
                              >
                              <template v-else-if="SubmittalField">
                                <span class="font-weight-bold">{{
                                  SubmittalField.text
                                }}</span>
                                must be filled out.
                              </template>
                            </span>
                          </div>
                          <div class="full-width d-flex flex-column" v-else>
                            <div
                              class="text-center font-italic full-width text-subtitle mb-1"
                            >
                              Program Requirements (per {{ CadenceUnit }})
                            </div>

                            <v-card
                              outlined
                              shaped
                              flat
                              class="pa-2 full-width rounded"
                            >
                              <div
                                class="d-flex justify-space-between ml-10 mr-5"
                              >
                                <span class="font-weight-black">
                                  Log type
                                </span>
                                <div class="d-flex align-center">
                                  <span class="font-weight-black"> Qty </span>
                                  <v-btn
                                    icon
                                    small
                                    class="ml-1"
                                    @click="display.configStep = 2"
                                    ><v-icon small>mdi-pencil</v-icon></v-btn
                                  >
                                </div>
                              </div>
                              <div
                                class="d-flex justify-space-between px-5 mx-5"
                                v-for="(entry, index) in input.resource !==
                                'Daily Logs'
                                  ? [
                                      {
                                        goal: input.goal,
                                        resource: input.resource
                                      }
                                    ]
                                  : [
                                      input.resources || [],
                                      [
                                        {
                                          resource: 'Daily Log Completion',
                                          goal: 1
                                        }
                                      ]
                                    ]?.flat()"
                                :key="index"
                              >
                                <span>{{
                                  {
                                    text: getFriendlyResourceName(
                                      entry.resource
                                    ),
                                    amount:
                                      48 -
                                      (getFriendlyResourceName(entry.resource)
                                        .length +
                                        ("" + entry.goal).length) /
                                        2
                                  } | padStart
                                }}</span>
                                <div class="ellipsis-fill"></div>
                                <span>{{
                                  {
                                    text: "" + entry.goal,
                                    amount:
                                      48 -
                                      (getFriendlyResourceName(entry.resource)
                                        .length +
                                        ("" + entry.goal).length) /
                                        2
                                  } | padEnd
                                }}</span>
                              </div>
                            </v-card>
                          </div>
                          <div class="d-flex align-center mt-5 pr-4">
                            <label class="body-1 font-weight-bold"
                              >Rewards
                            </label>
                            <v-btn
                              icon
                              small
                              class="ml-1"
                              @click="display.configStep = 3"
                              ><v-icon small>mdi-pencil</v-icon></v-btn
                            >
                          </div>
                          <div class="d-flex justify-space-between full-width">
                            <label class="pr-2">Base Reward: </label>
                            <span
                              >{{
                                {
                                  amount: input.reward,
                                  type:
                                    (Budget &&
                                      Budget.Award &&
                                      Budget.Award.awardName) ||
                                    "Cash"
                                } | award
                              }}
                              <template v-if="!IsSubmittal"
                                >per {{ CadenceUnit }}</template
                              ></span
                            >
                          </div>
                          <template v-if="!IsSubmittal">
                            <div
                              class="d-flex justify-space-between full-width"
                            >
                              <label class="pr-2">Initial Bonus: </label>
                              <span>{{
                                {
                                  amount: input.ongoingInitialPayoutAmount,
                                  type:
                                    (Budget &&
                                      Budget.Award &&
                                      Budget.Award.awardName) ||
                                    "Cash"
                                } | award
                              }}</span>
                            </div>
                            <div
                              class="d-flex justify-space-between full-width"
                            >
                              <label class="pr-2">Streak Bonus: </label>
                              <span>{{
                                {
                                  amount: input.ongoingStreakPayoutAmount,
                                  type:
                                    (Budget &&
                                      Budget.Award &&
                                      Budget.Award.awardName) ||
                                    "Cash"
                                } | award
                              }}</span>
                            </div>
                          </template>
                          <div
                            class="d-flex justify-space-between full-width mt-3"
                          >
                            <span>Max payout per person:</span
                            ><span class="font-weight-bold">
                              {{
                                {
                                  amount: ProgramCostPerPerson,
                                  type:
                                    Budget &&
                                    Budget.Award &&
                                    Budget.Award.awardName
                                } | award
                              }}</span
                            >
                          </div>
                          <div class="d-flex justify-space-between full-width">
                            <span>Max program payout:</span>
                            <span class="font-weight-bold">
                              {{
                                {
                                  amount: ProgramCost,
                                  type:
                                    Budget &&
                                    Budget.Award &&
                                    Budget.Award.awardName
                                } | award
                              }}</span
                            >
                          </div>
                          <v-spacer class="my-3" />
                          <div class="d-flex align-center pr-4">
                            <label class="body-1 font-weight-bold"
                              >Details
                            </label>
                            <v-btn
                              icon
                              small
                              class="ml-1"
                              @click="display.configStep = 3"
                              ><v-icon small>mdi-pencil</v-icon></v-btn
                            >
                          </div>
                          <div class="d-flex justify-space-between full-width">
                            <label class="pr-2">Timeframe: </label>
                            <span>{{ DateText }}</span>
                          </div>
                          <!-- <v-spacer class="my-3" /> -->
                          <div
                            class="d-flex flex-column align-start pa-0 ma-0"
                            style="width: 100%"
                          >
                            <div
                              class="d-flex justify-space-between"
                              style="width: 100%"
                            >
                              <template v-if="Budget">
                                <label class="pr-2">Budget: </label>

                                <span>{{ BudgetName }}</span>
                              </template>
                              <template v-else>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div
                                      @click="fillBudget"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <label class="pr-2 red--text"
                                        >Budget</label
                                      >
                                      <span class="red--text"
                                        >N/A (A budget is required)</span
                                      >
                                    </div>
                                  </template>
                                  <span> Click here to select a budget </span>
                                </v-tooltip>
                              </template>
                            </div>
                            <div class="d-flex justify-end full-width mt-1">
                              <v-btn
                                v-if="Budget"
                                outlined
                                rounded
                                dark
                                x-small
                                color="primary"
                                @click="display.fundingBudget = true"
                                >Add funds</v-btn
                              >
                            </div>
                            <em
                              class="red--text text-left"
                              v-if="InsufficientFunds"
                            >
                              Warning: Insufficient funds - program may not pay
                              out. Please select a different budget or click
                              "Add funds"
                            </em>
                          </div>
                          <div v-if="input.project" class="full-width">
                            <div class="d-flex justify-start pb-3">
                              <label class="body-1">
                                Who should receive weekly updates?
                              </label>
                            </div>
                            <div>
                              <v-autocomplete
                                multiple
                                chips
                                outlined
                                color="brandCyan"
                                deletable-chips
                                :loading="display.loading.people"
                                class="text-start text-left mb-4"
                                :menu-props="{ offsetY: true }"
                                :items="PeopleOptions"
                                v-model="input.managers"
                                placeholder="Start typing a name or email"
                                :search-input.sync="misc.managerSearchInput"
                                @change="misc.managerSearchInput = ''"
                              >
                                <template v-slot:item="data">
                                  <div
                                    class="font-weight-bold text-left text-truncate"
                                  >
                                    {{ data.item.text }}
                                  </div>
                                </template>
                              </v-autocomplete>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="5">
                      <LoadingDialog
                        :showDialog="display.loading.registration"
                        header="Please wait while we configure your reward"
                      ></LoadingDialog>
                      <label class="text-h6"> We'll take it from here! </label>
                      <div class="d-flex justify-start pb-5">
                        We're building your program now. If you scheduled it to
                        start immediately, it will be active in a few minutes,
                        otherwise, it will be scheduled to start on your chosen
                        start date.
                      </div>
                      <div class="d-flex justify-start">
                        <p>
                          If you have any questions, reach out to
                          ben@wewhistle.com
                        </p>
                      </div>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </template>
              <template v-else>
                <stripe-payment-methods-manager
                  :display-budget-transfer="false"
                  @reset="display.fundingBudget = false"
                  :budgets="data.budget"
                  :destination-budget="Budget"
                />
              </template>
            </v-navigation-drawer>
          </v-container>
        </v-card>
      </v-col>

      <v-col v-if="IsAdmin" lg="3" cols="12">
        <div class="d-flex flex-column align-start" style="height: 100%">
          <!-- style="min-height: 70vh" -->
          <v-card class="mr-4 full-width elevation-1" outlined flat>
            <div class="d-flex flex-column align-start text-no-wrap py-2 px-4">
              <span
                class="pr-5 text-h5 font-weight-bold mt-1"
                style="
                  font-family: 'Avenir' !important;
                  font-size: 1.3rem !important;
                "
              >
                Add an Incentive or Reward
              </span>
              <v-card
                v-for="template in fixtures.templates"
                :key="template.name"
                flat
                outlined
                @click="configure(template)"
                style="width: 100%"
                class="my-2 raise"
              >
                <!-- 
                mdiAccountHardHat
                mdiLightbulbOn10


               -->
                <v-card-text>
                  <div class="d-flex justify-start" style="width: 100%">
                    <v-avatar
                      rounded
                      color="deep-orange lighten-1"
                      class="mr-4"
                    >
                      <v-icon x-large dark>mdi-{{ template.icon }} </v-icon>
                    </v-avatar>
                    <div
                      class="d-flex flex-column align-start"
                      style="width: 100%"
                    >
                      <span
                        class="text-md-h6 subtitle-1 black--text font-weight-bold text-left"
                        style="
                          font-family: 'Avenir' !important;
                          font-size: 1rem !important;
                        "
                      >
                        {{ template.title }}
                      </span>
                      <span class="body-2 text-left">
                        {{ template.desc }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
          <!-- <v-card
            outlined
            elevation="1"
            class="mt-5"
            width="100%"
            v-if="CanViewDataTab"
          >
            <div class="d-flex flex-column align-start text-no-wrap py-2 px-4">
              <span
                class="pr-5 text-h5 font-weight-bold mt-1"
                style="
                  font-family: 'Avenir' !important;
                  font-size: 1.3rem !important;
                "
              >
                Explore Your Data
              </span>
              <v-card
                flat
                outlined
                style="width: 100%"
                class="my-2 raise"
                to="/procore-semantic-search"
              >
                <v-card-text>
                  <div class="d-flex justify-start" style="width: 100%">
                    <v-avatar rounded color="brandCyan" class="mr-4">
                      <v-icon x-large dark>mdi-image-multiple </v-icon>
                    </v-avatar>
                    <div
                      class="d-flex flex-column align-start"
                      style="width: 100%"
                    >
                      <span
                        class="text-md-h6 subtitle-1 black--text font-weight-bold text-left"
                        style="
                          font-family: 'Avenir' !important;
                          font-size: 1rem !important;
                        "
                      >
                        Photo Search
                      </span>
                      <span class="body-2 text-left">
                        Explore photos searching with text or images
                      </span>
                    </div>
                  </div>
                </v-card-text></v-card
              >
            </div></v-card
          > -->
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/**
 * @todo Bring in Wallet
 */
import axios from "../axios";
import numeral from "numeral";
import LoadingDialog from "@/components/loadingDialog.vue";
import PillNav from "@/components/PillNav.vue";
import { mapState } from "vuex";
import dailyLogResources from "../shared_data/procore-daily-log-resources.json";
import tz from "../assets/timezones.json";
import { titleCase /* WORD_SEPARATORS */ } from "title-case";

import ProgramService from "@/services/ProgramService.js";
import BudgetService from "@/services/BudgetService.js";
const america = tz
  .sort((a, b) => b.indexOf("America") - a.indexOf("America"))
  .filter(i => i.indexOf("America") !== -1)
  .reverse();
const rest = tz.filter(i => i.indexOf("America") === -1);
const timezones = [...america, ...rest];
const resourceTitleMap = {
  "Safety Violations": "Safety Reporting Incentive",
  Images: "Daily Log Photo Incentive",
  Submittals: "Submittal Completion Incentive",
  "Daily Logs": "Daily Log Completion Incentive"
};

const fmt = {
  asset: amount => numeral(amount).format("0,0.00"),
  amount: function(v) {
    const { amount, type } = v;
    const denom = {
      prefix: "",
      suffix: ""
    };
    if (type === "Cash") denom.prefix = "$";
    else denom.suffix = " " + type;
    return `${denom.prefix}${this.asset(amount)}${denom.suffix}`;
  }
};
import moment from "moment-timezone";
import StripePaymentMethodsManager from "../components/stripe/StripePaymentMethodsManager.vue";
import MarqetaWidgetManagerV2 from "../components/wallet/MarqetaWidgetManagerV2.vue";
const MAX_ERRORS = 10;
const IGNORED_FIELDS = [
  "private",
  "sub_job_id",
  "specification_section_id",
  "submittal_log_status_id"
];
export default {
  name: "Procore",
  title: "Whistle | Procore",

  components: {
    LoadingDialog,
    PillNav,
    MarqetaWidgetManagerV2,
    StripePaymentMethodsManager
  },
  mounted() {
    // alert("whoaoaaaaa");
  },
  filters: {
    padStart(v) {
      return v.text; //v.text.padStart(v.amount, ".");
    },
    padEnd(v) {
      return v.text; //v.text.padEnd(v.amount, ".");
    },
    award(v) {
      return fmt.amount(v);
    },
    asset(v) {
      return fmt.asset(v);
    },
    payoutFrequency(program) {
      switch (program.ruleAggregation.toLowerCase()) {
        case "daily":
          return "per Day";
        case "weekly":
          return "per Week";
        case "monthly":
          return "per Month";
      }
    },
    vendor(person) {
      return person?.vendor?.name || "";
    },
    jobTitle(person) {
      return person?.job_title || "";
    },
    project(id, project) {
      return project?.name || "";
    }
  },
  data() {
    return {
      cache: {},
      steps: [
        {
          step: 1,
          icon: "account-hard-hat"
        },
        {
          step: 2,
          icon: "clipboard-check-multiple"
        },
        {
          step: 3,
          icon: "trophy"
        },
        {
          step: 4,
          icon: "rocket-launch"
        }
      ],
      misc: {
        managerSearchInput: "",
        vcurrency: {
          props: {
            locale: "en-US",
            decimalLength: 2,
            autoDecimalMode: true,
            min: null,
            max: null,
            defaultValue: 0,
            valueAsInteger: false,
            allowNegative: true
          }
        }
      },
      display: {
        showInactiveProjects: false,
        fundingBudget: false,
        configuration: false,
        configStep: 1,
        start: false,
        end: false,
        bonuses: {
          initial: false,
          streak: false
        },
        loaderValue: 0,
        loading: {
          step1: false,
          registration: false,
          programs: false,
          projects: false,
          people: false,
          budgets: false
        }
      },
      input: {
        managers: [],
        project: null,
        company: null,
        filter: "",
        people: [],
        resource: "Safety Violations", // ProgramElements.MetricDef.definition1
        cadence: "Daily", // ProgramElements.MetricDef.definition3
        goal: 1, // ProgramElements.value
        reward: 0, // payoutAmount
        rewardBudget: null, // Budget
        ongoingStreakPayoutAmount: 15,
        ongoingInitialPayoutAmount: 5,
        startDate: null, // startDate
        endDate: null, // endDate
        dates: [],
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        fields: [null],
        resources: [
          {
            resource: "",
            goal: 3
          }
        ]
      },
      fixtures: {
        dailyLogResources,
        permissions: [
          {
            title: "Participant"
          },
          {
            title: "Manager"
          }
        ],
        timezones: timezones
          .sort((a, b) => b.indexOf("America") - a.indexOf("America"))
          .map(t => ({
            text: t,
            value: t
          })),
        resources: ["Images", "Safety Violations"]
          .map(i => ({
            text: i + " added to daily log",
            value: i
          }))
          .concat([
            {
              text: "Submittal field completion",
              value: "Submittals"
            },
            {
              text: "Daily Logs marked complete",
              value: "Daily Logs"
            }
          ]),
        cadences: [
          ["Day", "Daily"],
          ["Week", "Weekly"],
          ["Month", "Monthly"]
        ].map(([text, value]) => ({
          text,
          value
        })),
        _cadences: ["Daily", "Weekly", "Monthly"],
        templates: [
          {
            name: "Daily Logs",
            title: "Improve Daily Log Completion",
            icon: "notebook-multiple",
            desc: "Add incentives to improve Daily Log completion",
            presets: () => ({
              resource: "Daily Logs", // ProgramElements.MetricDef.definition1
              cadence: "Daily", // ProgramElements.MetricDef.definition3
              goal: 1, // ProgramElements.value
              reward: 5, // payoutAmount
              ongoingStreakPayoutAmount: 15,
              ongoingInitialPayoutAmount: 5,
              resources: [
                {
                  resource: "Manpower Logs",
                  goal: 3
                },
                {
                  resource: "Images",
                  goal: 8
                },
                {
                  resource: "Notes Logs"
                },
                {
                  resource: "Weather Logs"
                },
                {
                  resource: "Visitor Logs"
                }
              ].map(o => ({
                goal: 1,
                ...o
              })),
              dates: [
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0),
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .add(4, "weeks")
              ].map(m => m.toDate().toISOString())
            })
          },
          {
            name: "Safety Violations",
            title: "Improve Safety and Reporting",
            icon: "account-hard-hat",
            desc: "Add incentives to improve safety reporting in the Daily Log",
            presets: () => ({
              resource: "Safety Violations", // ProgramElements.MetricDef.definition1
              cadence: "Daily", // ProgramElements.MetricDef.definition3
              goal: 3, // ProgramElements.value
              reward: 3, // payoutAmount
              ongoingStreakPayoutAmount: 15,
              ongoingInitialPayoutAmount: 5,
              dates: [
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0),
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .add(4, "weeks")
              ].map(m => m.toDate().toISOString())
            })
          },
          {
            name: "Images",
            title: "Improve Photo Logs",
            icon: "account-hard-hat",
            desc:
              "Add incentives to improve site photo submissions in the Daily Log",
            presets: () => ({
              resource: "Images", // ProgramElements.MetricDef.definition1
              cadence: "Daily", // ProgramElements.MetricDef.definition3
              goal: 5, // ProgramElements.value
              reward: 5, // payoutAmount
              ongoingStreakPayoutAmount: 15,
              ongoingInitialPayoutAmount: 5,
              dates: [
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .toDate(),
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .add(4, "weeks")
                  .toDate()
                  .toISOString()
              ]
            })
          },
          {
            name: "Submittals",
            title: "Improve Submittal Completion",
            icon: "clipboard-check-outline",
            desc: "Incentivize completeness of submittal fields",
            presets: () => ({
              resource: "Submittals", // ProgramElements.MetricDef.definition1
              cadence: "Daily", // ProgramElements.MetricDef.definition3
              goal: 100, // ProgramElements.value
              reward: 100, // payoutAmount
              ongoingStreakPayoutAmount: 0,
              ongoingInitialPayoutAmount: 0,
              fields: [
                "number",
                "submittal_manager_id",
                "title",
                "specification_section",
                "description",
                // "",
                "status", // *
                "lead_time", // *
                "required_on_site_date", // *
                "responsible_contractor_id"
              ],
              dates: [
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .toDate(),
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .add(4, "months")
                  .toDate()
                  .toISOString()
              ]
            })
          }

          // {
          //   name: "idea",
          //   title: "Suggest an Incentive or Reward",
          //   icon: "lightbulb-on-10",
          //   desc: "Have a great idea for incentivizing behaviors in your organization? Let us know!",
          // },
        ]
      },
      data: {
        program: null,
        project: null,
        companies: null,
        people: null,
        budget: null,
        configurableFields: null
      },
      errors: [],
      validation: {
        cadence: [v => Boolean(v) || "Cadence is required"],
        project: [v => Boolean(v) || "Project required"],
        company: [v => Boolean(v) || "Company required"],
        date: [v => v.indexOf("~") !== -1 || "Program date range required"],
        goal: [
          v => v >= 0 || "Goal amount required",
          v => v > 0 || "Goal amount must be greater than 0"
        ],
        reward: [
          v => v >= 0 || "Payout amount required",
          v => v > 0 || "Payout amount must be greater than 0",
          v =>
            (v.toString().split(".")?.[1]?.length || 0) <= 2 ||
            "Only two decimal places allowed"
        ],
        percent: [
          v => v >= 0 || "Percentage required",
          v =>
            (v > 0 && v <= 100) ||
            "Percentage must be greater than 0 and less than 100"
        ],
        bonus: [
          v => v >= 0 || "Bonus required",
          v =>
            (v.toString().split(".")?.[1]?.length || 0) <= 2 ||
            "Only two decimal places allowed"
          // (v) => v > 0 || "Bonus must be greater than 0",
        ],
        amount: [v => v >= 0 || "Amount required"],
        budget: [b => Boolean(b) || "Budget required"],
        recipient: [r => Boolean(r) || "Recipient required"],
        frequency: [r => Boolean(r) || "Payout frequency required"],
        start: [r => Boolean(r) || "Start date required"],
        end: [r => Boolean(r) || "End date required"]
      },

      headers: [
        // {
        //   text: "Project",
        //   value: "name",
        //   sortable: false
        // },
        { text: "Project", value: "project", sortable: false },

        { text: "Metric", value: "metric", sortable: false },
        { text: "Reward", value: "reward", sortable: false }
        // { text: "Carbs (g)", value: "carbs" },
        // { text: "Protein (g)", value: "protein" },
        // { text: "Iron (%)", value: "iron" },
      ]
    };
  },
  computed: {
    DailyLogCadenceEditable() {
      return false;
    },
    PeopleOptions() {
      return (this.People || []).map(p => ({
        text: `${p.name} ${p?.vendor?.name ? `(${p.vendor.name})` : ""}`,
        value: p
      }));
    },
    CompanyIdParams() {
      return {
        params: this.input.company ? { companyId: this.input.company } : {}
      };
    },
    MultiCompany() {
      return this.Companies?.length > 1;
    },
    Companies() {
      if (!this.data.companies) {
        this.getCompanies();
      }
      return this.data.companies || [];
    },
    GoalMax() {
      if (this.input.resource !== "Daily Logs") return 1000;
      else {
        if (this.input.cadence === "Weekly") return 7;
        else if (this.input.cadence === "Monthly") return 31;
        else if (this.input.cadence === "Daily") return 1;
        else return null;
      }
    },
    SubmittalField() {
      return this.ConfigurableFieldOptions.find(
        o => o.value === this.SubmittalFields[0]
      );
    },
    FieldsRequiredForCompletion() {
      return Math.ceil(this.SubmittalFields.length * (this.input.goal / 100));
    },
    ConfigurableFieldOptions() {
      return (
        (this.ConfigurableFields &&
          Object.entries(this.ConfigurableFields?.fields || {}).map(
            ([k, e]) => ({
              text: e?.label || titleCase((e?.name || k).replace(/_/gi, " ")),
              value: e?.name || k
            })
          )) ||
        []
      )
        .concat([
          {
            text: "Lead Time",
            value: "lead_time"
          },
          {
            text: "Status",
            value: "status"
          },
          {
            text: "Required On-Site Date",
            value: "required_on_site_date"
          },
          {
            text: "Specification Section",
            value: "specification_section"
          }
        ])
        .filter(f => !IGNORED_FIELDS.includes(f.value));
    },
    IsSubmittal() {
      return this.input.resource === "Submittals";
    },
    AvailableFields() {
      return this.ConfigurableFieldOptions.filter(
        f => this.SubmittalFields.indexOf(f.value) === -1
      );
    },
    SubmittalFields() {
      return this.input.fields.filter(Boolean);
    },
    IsCashBudget() {
      return this?.Budget?.Award?.awardName === "Cash";
    },
    Now() {
      return moment().format("YYYY-MM-DD");
    },
    ...mapState([
      "userProfile",
      "roles"
      // "permissions",
      // "displayWalletMenu",
      // "walletMenuScreen"
    ]),
    DatePickerType() {
      return this.input.cadence.toLowerCase() === "monthly" ? "month" : "date";
    },
    StepOneLoading() {
      return this.display.loading.step1;
    },
    ProgramCostPerPerson() {
      const cadence = this.input.cadence.toLowerCase();
      const reward = Number(this.input.reward);
      if (this.IsSubmittal) return reward;
      const numPeriods =
        this.Dates.length === 2
          ? Math.abs(
              moment(this.Dates[1]).diff(
                this.Dates[0],
                cadence === "monthly"
                  ? "months"
                  : cadence === "weekly"
                  ? "weeks"
                  : "days"
              )
            ) + 1
          : 1;
      const initialBonus = Number(this.input.ongoingInitialPayoutAmount);
      const streakBonus = Number(this.input.ongoingStreakPayoutAmount);
      console.log({ reward, numPeriods, initialBonus, streakBonus });
      return reward * numPeriods + initialBonus + streakBonus;
    },
    ProgramCost() {
      const users = this.input.people.length;
      const costPerUser = this.ProgramCostPerPerson;
      console.log({ users, costPerUser });
      return costPerUser * users;
    },
    InsufficientFunds() {
      if (this.Budget && this.ProgramCost) {
        return this.Budget.balance < this.ProgramCost;
      } else {
        return false;
      }
    },
    CadenceUnit() {
      const cadence = this.fixtures.cadences.find(
        c => c.value === this.input.cadence
      );
      return cadence.text?.toLowerCase();
    },
    BudgetName() {
      return this.Budget ? this.Budget.budgetName : "N/A";
    },
    ProjectName() {
      return this.Project ? this.Project.name : "N/A";
    },
    ResourceTitle() {
      return this.input.resource && resourceTitleMap[this.input.resource];
    },
    NextStepReady() {
      let ret = true;
      if (this.display.configStep === 1 && !this.StepOneReady) ret = false;
      else if (this.display.configStep === 2) ret = this.StepTwoReady;
      // this.$refs.step2form.validate()
      else if (this.display.configStep === 3 && !this.StepThreeReady)
        ret = false;
      // else if (this.display.configStep === 4 && !this.StepFourReady) {
      //   return false;
      // }
      // alert("Checking NextStepReady: " + ret);
      return ret;
    },
    // StepFourReady() {
    //   console.log("Budget", this.Budget);
    //   return ;
    // },
    Dates() {
      return this.input.dates
        .slice()
        .sort((a, b) => {
          return moment(a).unix() - moment(b).unix();
        })
        .map((m, i) =>
          this.input.cadence.toLowerCase() === "monthly"
            ? i === 1
              ? moment(m).endOf("month")
              : i === 0
              ? moment(m).startOf("month")
              : moment(m)
            : this.input.cadence.toLowerCase() === "weekly"
            ? i === 1
              ? moment(m).endOf("week")
              : i === 0
              ? moment(m).startOf("week")
              : moment(m)
            : moment(m)
        )
        .map(m => m.toDate().toISOString());
    },
    DateText() {
      console.log("Dates:", this.Dates.slice());
      const dates = [];
      if (moment(this.Dates?.[0]).unix() < moment().unix())
        dates.push(
          moment()
            .toDate()
            .toISOString()
        );
      else dates.push(this.Dates?.[0]);
      if (moment(this.Dates?.[1]).unix() < moment().unix())
        dates.push(
          moment()
            .toDate()
            .toISOString()
        );
      else dates.push(this.Dates?.[1]);
      const filtered = dates.filter(Boolean);
      console.log("Dates..", dates.length, filtered.length, {
        dates,
        filtered
      });
      return filtered.length === 2
        ? filtered
            .sort((a, b) => moment(a).unix() - moment(b).unix())
            .map(d => moment(d).format("MMM DD, YYYY"))
            .join(" to ")
        : "";
    },
    StepOneReady() {
      // return (
      //   this.input.project &&
      //   this.input.resource &&
      //   Number(this.input.goal) &&
      //   Number(this.input.reward) &&
      //   this.input.rewardBudget &&
      //   this.input.cadence &&
      //   this.$refs.form.validate()
      // );
      return !!(this.input.project && this.input.people.length);
    },
    StepTwoReady() {
      /**
       * (
        this.input.resource &&
          Number(this.input.goal) &&
          this.input.goal > 0 &&
          Number(this.input.reward) &&
          this.input.reward > 0 &&
          this.input.rewardBudget &&
          this.input.cadence &&
          this.input?.dates?.length === 2 &&
          (this.input.reward.toString().split(".")?.[1]?.length || 0) <= 2,
        (this.input.ongoingInitialPayoutAmount.toString().split(".")?.[1]
          ?.length || 0) <= 2,
        (this.input.ongoingStreakPayoutAmount.toString().split(".")?.[1]
          ?.length || 0) <= 2
      );
       */
      /*
        DC - revision 2
         this.input.resource &&
        this.input.reward &&
        this.input.reward > 0 &&
        this.input.rewardBudget &&
        (this.input.reward.toString().split(".")?.[1]?.length || 0) <= 2 &&
        (!this.IsSubmittal
          ? this.input.goal &&
            this.input.goal > 0 &&
            this.input.cadence &&
            (this.input.ongoingInitialPayoutAmount.toString().split(".")?.[1]
              ?.length || 0) <= 2 &&
            (this.input.ongoingStreakPayoutAmount.toString().split(".")?.[1]
              ?.length || 0) <= 2
          : this.SubmittalReady) &&
        this.input?.dates?.length === 2 &&
        (this.input.resource !== "Daily Logs" ||
          (this.input.resources.length &&
            this.input.resources.every(r => r.resource && r.goal)))

      */
      return (
        this.input.resource &&
        (!this.IsSubmittal
          ? this.input.goal && this.input.goal > 0 && this.input.cadence
          : this.SubmittalReady) &&
        (this.input.resource !== "Daily Logs" ||
          (this.input.resources.length &&
            this.input.resources.every(r => r.resource && r.goal)))
      );
    },
    SubmittalReady() {
      return (
        this.SubmittalFields.length && this.input.goal && this.input.goal > 0
      );
    },
    StepThreeReady() {
      return !!(
        this.input.reward &&
        this.input.reward > 0 &&
        this.input.rewardBudget &&
        (this.input.reward?.toString()?.split(".")?.[1]?.length || 0) <= 2 &&
        this.Budget &&
        (this.input.ongoingStreakPayoutAmount.toString().split(".")?.[1]
          ?.length || 0) <= 2 &&
        Number(this.input.ongoingStreakPayoutAmount || 0) >= 0 &&
        Number(this.input.ongoingInitialPayoutAmount || 0) >= 0 &&
        (this.input.ongoingInitialPayoutAmount.toString().split(".")?.[1]
          ?.length || 0) <= 2 &&
        this.input?.dates?.length === 2
      );
    },
    IsAdmin() {
      const roles = ["40ClientAdminV2", "10PeopleAdminV2"];
      return this.roles.find(x => roles.includes(x));
    },
    Project() {
      return this.Projects.find(p => p.id === this.input.project);
    },
    Programs() {
      if (!this.data.program) {
        this.getPrograms();
      }
      return this.data.program || [];
    },
    ConfigurableFields() {
      if (!this.data.configurableFields) {
        this.getConfigurableFields();
      }
      return this.data.configurableFields || {};
    },
    Projects() {
      if (!this.data.project) {
        this.getProjects();
      }
      const ret =
        this?.data?.project?.map?.(v => ({
          ...v,
          desc: `${v.project_number ? `[${v.project_number}] ` : ""}${v.name}`
        })) || [];
      if (!this.display.showInactiveProjects) {
        return ret.filter(
          (
            v //
          ) => v?.status?.toLowerCase?.() === "active"
        );
      } else return ret;
    },
    Budgets() {
      if (!this.data.budget) {
        this.getBudgets();
      }
      return this.data.budget || [];
    },
    People() {
      if (!this.data.people) {
        this.getPeople();
      }
      return this.data.people || [];
    },
    Budget() {
      return this.Budgets.find(b => b.budgetId === this.input.rewardBudget);
    },
    BudgetOptions() {
      return this.Budgets.map(budget => ({
        text: `${budget.budgetName} (${fmt.amount({
          amount: budget.balance,
          type: budget.Award.awardName
        })})`,
        value: budget.budgetId
      }));
    },
    MatchingPeople() {
      return this.People.filter(p => {
        return (
          [p.name, p?.vendor?.name, p.email_address]
            .filter(Boolean)
            .join(" ")
            .toLowerCase()
            .indexOf(this.input.filter.toLowerCase()) !== -1
        );
      });
    },
    CanViewDataTab() {
      return this?.userProfile?.businessEmail?.includes("@wewhistle.com");
    }
  },
  watch: {
    "input.company": async function(n, o) {
      await this.getProjects();
      // Wipe project if company is changing
      this.input.project = null;
    },
    "input.resource": function(n, o) {
      if (n !== o && n) {
        this.configure(
          this.fixtures.templates.find(t => t.name === this.input.resource)
        );
      }
    },
    "input.fields": {
      deep: true,
      handler(/* n, o */) {
        if (this.input.fields[this.input.fields.length - 1] !== null) {
          this.input.fields.push(null);
          console.log(this.$refs.submittalFields);
          setTimeout(() => {
            this.$refs?.submittalFields?.scrollTop &&
              (this.$refs.submittalFields.scrollTop = this.$refs?.submittalFields?.scrollHeight);
          }, 50);
        }
      }
    },
    "input.project": function(n, v) {
      if (n !== v) {
        this.data.people = null;
        this.getConfigurableFields();
        this.input.people = [];
      }
    },
    "input.cadence": function(n) {
      if (this.input.resource == "Daily Logs" && n === "Daily") {
        this.input.goal = 1;
      }
      this.input.dates = [
        moment()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0),
        moment()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .add(4, "weeks")
      ].map(m => m.toDate().toISOString());
    },
    "display.configuration": function(n /* v */) {
      this.errors = [];
      if (!n) {
        this.display.configStep = 1;
        this.display.fundingBudget = false;
        Object.assign(this.input, {
          managers: [],

          project: null,
          filter: "",
          people: [],
          cadence: "Daily", // ProgramElements.MetricDef.definition3
          goal: 0, // ProgramElements.value
          reward: 0, // payoutAmount
          rewardBudget: null, // Budget
          ongoingStreakPayoutAmount: 0,
          ongoingInitialPayoutAmount: 0,
          startDate: null, // startDate
          endDate: null, // endDate
          dates: [],
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          fields: [null],
          resources: [
            {
              resource: "",
              goal: 3
            }
          ]
        });
        if (this.data.budget) this.preloadBudget();

        this.$refs.form?.resetValidation?.();
      }
    }
  },
  methods: {
    setPermission(permission, person) {
      console.log({ permission, person });
    },
    openPlatform() {
      window.open("/", "_blank");
    },
    getFriendlyResourceName(val) {
      if (!val || !val?.length) return "";

      return (
        this.fixtures.dailyLogResources.find(x => x.value == val)?.text || val
      );
    },
    updateTotalFields(v) {
      v = Number(v);
      this.input.goal = Math.min(
        Math.round((v / this.SubmittalFields.length) * 100),
        100
      );
    },
    async fillBudget() {
      this.display.configStep = 2;
      await this.$nextTick();
      this.$refs?.step2form?.validate();
    },
    edit() {
      this.display.configStep--;
    },
    save(menu, date) {
      this.$refs[menu].save(date);
    },
    async nextStep(step) {
      console.log("nextStep()", { step, currentStep: this.display.configStep });
      if (
        (typeof step === "number" &&
          step > this.display.configStep &&
          !this.NextStepReady) ||
        (step >= 2 && !this.StepOneReady) ||
        (step >= 3 && !this.StepTwoReady)
      )
        return;
      if (this.display.configStep === 5) {
        this.stopConfiguring();
        return;
      }
      console.log("Made it here!");
      const initialStep = this.display.configStep;
      if (typeof step === "number") {
        // if (this.display.configStep !== 3 && step === 4) return;
        this.display.configStep = step;
      } else {
        this.display.configStep++;
      }
      if (this.display.configStep === 5) {
        console.log("here we r");
        this.display.configStep = initialStep;
        if (
          initialStep === 4 &&
          this.StepOneReady &&
          this.StepTwoReady &&
          this.StepThreeReady
          // this.StepFourReady
        ) {
          await this.completeRegistration();
          this.display.configStep = 5;
        }
      }
    },
    prevStep() {
      this.display.configStep--;
    },
    async configure(template) {
      // alert("configuring...");
      // console.log('here...', new Error().stack);
      if (template.name === "idea") {
        return;
      }
      if (this.display.configuration) {
        this.display.configuration = false;
        await this.$nextTick();
      }
      this.display.configuration = true;
      const preset =
        typeof template.presets === "function" ? template.presets() : template;
      Object.assign(this.input, preset);
      if (this.input.ongoingInitialPayoutAmount) {
        this.display.bonuses.initial = true;
      }
      if (this.input.ongoingStreakPayoutAmount) {
        this.display.bonuses.streak = true;
      }
      console.log({ this: this });
    },
    stopConfiguring() {
      // alert("stop configuring...");
      this.display.configuration = false;
      this.display.configStep = 1;

      this.data.people = null;
    },

    async getPrograms() {
      if (this.errors.filter(i => i === "programs").length >= MAX_ERRORS)
        return;
      if (this.display.loading.programs) return;
      this.display.loading.programs = true;

      return ProgramService.getProcorePrograms(this.CompanyIdParams)
        .then(res => {
          this.data.program = res.data.rows;
        })
        .catch(() => {
          this.errors.push("programs");
        })
        .finally(() => {
          this.display.loading.programs = false;
        });
    },
    async getConfigurableFields() {
      if (this.display.loading.configurableFields) return;
      if (!this.input.project) return;
      this.display.loading.configurableFields = true;
      if (this.cache?.[this.input.project]?.fields) {
        this.data.configurableFields = this.cache?.[this.input.project]?.fields;
        this.input.fields = this.input.fields.filter(f =>
          this.ConfigurableFieldOptions.find(cf => cf?.value === f)
        );
      }
      return ProgramService.getProcoreConfigurableFields(
        this.input.project,
        this.CompanyIdParams
      )
        .then(res => {
          console.log("GOT THOSE FIELDS", res.data);
          this.data.configurableFields = res.data;
          if (!this.cache?.[this.input.project])
            this.cache[this.input.project] = {};
          this.cache[this.input.project].fields = res.data;
          this.input.fields = this.input.fields.filter(f =>
            this.ConfigurableFieldOptions.find(cf => cf?.value === f)
          );
        })
        .finally(() => {
          this.display.loading.configurableFields = false;
        });
    },
    async getCompanies() {
      if (this.errors.filter(i => i === "companies").length >= MAX_ERRORS)
        return;
      this.display.loading.companies = true;
      return ProgramService.getProcoreCompanies()
        .then(res => {
          this.data.companies = res.data;
          if (!this.input.company) this.input.company = res.data[0].id;
        })
        .catch(() => {
          this.errors.push("companies");
        })
        .finally(() => {
          this.display.loading.companies = false;
        });
    },
    async getProjects() {
      if (this.errors.filter(i => i === "projects").length >= MAX_ERRORS)
        return;
      if (this.display.loading.projects) return;

      this.display.loading.projects = true;
      try {
        let opts = this.CompanyIdParams;
        if (!opts?.params) opts.params = {};
        const res = await ProgramService.getProcoreProjects(opts);
        const projectsArray = res.data;
        console.log("Got projects", projectsArray);
        this.data.project = projectsArray;
        // Set projects false so it's usable while it's fetching fresh data
        this.display.loading.projects = false;

        // Now re-fetch projects and force reload it
        opts.params.refresh = 1;
        const secondRes = await ProgramService.getProcoreProjects(opts);
        const secondProjectsArray = secondRes.data;
        console.log("Got projects again", secondProjectsArray);
        this.data.project = secondProjectsArray;
      } catch (e) {
        console.log("Error in getProjects", e);
        this.errors.push("projects");
      } finally {
        this.display.loading.projects = false;
      }
    },
    async getBudgets() {
      if (this.errors.filter(i => i === "budgets").length >= MAX_ERRORS) return;
      if (this.display.loading.budgets) return;

      this.display.loading.budgets = true;
      return BudgetService.getBudgetsV2({ expand: "Award", limit: 200 })
        .then(res => {
          this.data.budget = res.data.result.rows;
          this.preloadBudget();
        })
        .catch(() => {
          this.errors.push("budgets");
        })
        .finally(() => {
          this.display.loading.budgets = false;
        });
    },
    preloadBudget() {
      console.log("Preloading intro", this.input.rewardBudget);
      if (this.input.rewardBudget) return;
      // this.input.rewardBudget = this.data.budget[0].budgetId;
      const clientBudgets = this.data.budget.filter(
        x =>
          x.clientId == this.userProfile?.clientId &&
          x.status == "Active" &&
          Number(x.balance) > 0
      );
      console.log("Preloading budgets", clientBudgets);

      const acctBalance = clientBudgets.find(x => x.masterBudget);
      const cashBudget = clientBudgets.find(x => x.awardId == 1);
      if (acctBalance) this.input.rewardBudget = acctBalance.budgetId;
      else if (cashBudget) this.input.rewardBudget = cashBudget.budgetId;
      else if (clientBudgets.length)
        this.input.rewardBudget = clientBudgets[0].budgetId;
      else if (this.data.budget.length)
        this.input.rewardBudget = this.data.budget[0].budgetId;
    },
    async getPeople() {
      if (this.errors.filter(i => i === "people").length >= MAX_ERRORS) return;
      if (this.display.loading.people) return;

      if (!this.input.project) return;
      if (this.cache?.[this.input.project]?.people) {
        this.data.people = this.cache?.[this.input.project]?.people;
        return this.data.people;
      }
      this.display.loading.people = true;
      const PARTICIPANT = 0;
      return ProgramService.getProcorePeopleInProject(
        this.input.project,
        this.CompanyIdParams
      )
        .then(res => {
          this.data.people = res.data.map(p => ({
            ...p,
            $permission: PARTICIPANT
          }));
          if (!this.cache[this.input.project])
            this.cache[this.input.project] = {};
          this.cache[this.input.project].people = this.data.people.slice();
        })
        .catch(() => {
          this.errors.push("people");
        })
        .finally(() => {
          this.display.loading.people = false;
          // alert("done loading people");
        });
    },
    async completeRegistration() {
      if (this.display.loading.registration) return;

      if (this.input.dates.length !== 2) return;
      this.input.resources = this.input.resources.filter(
        r => r.resource && r.goal
      );
      const dates = [];
      if (moment(this.Dates?.[0]).unix() < moment().unix())
        dates.push(
          moment()
            .toDate()
            .toISOString()
        );
      else dates.push(this.Dates?.[0]);
      if (moment(this.Dates?.[1]).unix() < moment().unix())
        dates.push(
          moment()
            .toDate()
            .toISOString()
        );
      else dates.push(this.Dates?.[1]);

      const cadence = this.input.cadence.toLowerCase();
      const period =
        cadence === "monthly" ? "month" : cadence === "weekly" ? "week" : "day";
      dates[0] = moment(dates[0])
        .utcOffset(moment.tz(this.input.timezone).utcOffset())
        .milliseconds(0)
        .seconds(0)
        .minutes(0)
        .hours(0)
        .utcOffset(0)
        .toDate()
        .toISOString();
      dates[1] = moment(dates[1])
        .utcOffset(moment.tz(this.input.timezone).utcOffset())
        .milliseconds(0)
        .seconds(0)
        .endOf(period)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .utcOffset(0)
        .toDate()
        .toISOString();
      this.input.startDate = dates[0];
      this.input.endDate = dates[1];

      this.display.loading.registration = true;
      if (this.input.resource === "Daily Logs") {
        this.input.cadence = "Daily";
        this.input.goal = 1;
      }

      // We don't need to re-fetch the user since we have it in the app already
      // const user = await UserService.userProfileByEmail();
      let user = JSON.parse(JSON.stringify(this.userProfile));
      for (const key in user) {
        if (Object.hasOwn(user, key)) {
          if (Array.isArray(user[key])) delete user[key];
        }
      }

      await ProgramService.procoreCompleteRegistration(
        {
          user,
          project: this.Project,
          participants: this.input.people.map(p => ({
            ...p,
            $permission: this.fixtures.permissions[p.$permission]
          })),
          misc: {
            ...this.input,
            fields: this.SubmittalFields,
            cadence: this.IsSubmittal ? "ONE_TIME" : this.input.cadence,
            fieldLabels: this.SubmittalFields.map(
              f => this.ConfigurableFieldOptions.find(o => o.value === f).text
            )
          }
        },
        this.CompanyIdParams
      ).finally(async () => {
        await this.getPrograms().finally(() => {
          this.display.loading.registration = false;
        });
      });
    },
    userRowClicked(user) {
      let index = this.input.people.findIndex(x => x.id === user?.id);
      if (index === -1) this.input.people.push(user);
      else this.input.people.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.raise:hover,
.raise:focus {
  box-shadow: 0 0.25em 0.25em -0.4em black;
  transform: translateY(-0.05em);
}

.rewardees {
  min-height: 2.5em;
}
.person-selection {
  border-bottom: 1px solid rgb(203, 213, 219);
}
.flat {
  box-shadow: none !important;
  border: none;
}

/* Styling for data table */
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}
.table >>> .v-data-table-header {
  background-color: white;
}
.text-h5 {
  font-family: "Avenir" !important;
}
.text-h6 {
  font-family: "Avenir" !important;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.right-input >>> input {
  text-align: right !important;
}
.ellipsis-between {
  white-space: nowrap; /* Prevent wrapping onto multiple lines */
}

.ellipsis-between::after {
  content: "..."; /* Add the ellipsis content */
  padding: 0 0.2em; /* Add spacing around the ellipsis */
  display: none; /* Initially hide the ellipsis */
}

.ellipsis-between:hover .ellipsis-between::after {
  display: inline; /* Show ellipsis on hover */
}
.v-list-item__content {
  text-align: left !important;
}
.ellipsis-fill {
  flex-shrink: 0 !important;
  flex-grow: 1 !important;
  border-bottom: dotted black 1px;
  transform: translateY(-7px);
}

/* Changes the close icon's color for selected audience chips */
.audience-chip >>> .v-chip__close {
  color: lightgray;
}
</style>
